import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AOS from "aos";
import "aos/dist/aos.css";

import "./assets/scss/styles.scss";
import "aos/dist/aos.css";
import "remixicon/fonts/remixicon.css";

import TheLayout from "./TheLayout";
import routes from "./routes";

function App() {
  React.useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<TheLayout />}>
            {routes.map((route) => {
              return (
                route.component && (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<route.component />}
                  />
                )
              );
            })}
          </Route>
        </Routes>
      </BrowserRouter>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        icon
      />
    </>
  );
}

export default App;
