import { CssBaseline } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import i18next from "i18next";

//transalte
import global_en from "./transalate/en/global.json"
import global_id from "./transalate/id/global.json"
import { I18nextProvider } from 'react-i18next';


i18next.init({
  interpolation: { escapeValue: false },
  lng: "id",
  resources: {
    en: {
      global: global_en
    },
    id: {
      global: global_id
    }
  }
})


const root = ReactDOM.createRoot(
  document.getElementById('root')
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CssBaseline />
      <I18nextProvider i18n={(i18next)}>
        <App />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
