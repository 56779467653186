import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appState: ""
};

export const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setAppState: (state, action) => {
      state.appState = action.payload;
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setNews: (state, action) => {
      state.news = action.payload;
    },
  }
});

export const {
  setAppState,
  setProduct,
  setNews
} = appStateSlice.actions;

export default appStateSlice.reducer;
