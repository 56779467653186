import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Dialog from '@mui/material/Dialog';
import { setProduct } from "../../redux/features/appStateSlice"
import { useTranslation } from "react-i18next";
import "./style.scss";


const Header = ({ isTranslated, translateContent, resetTranslation }) => {
  const [t, i18n] = useTranslation("global")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const [translate, setTranslate] = useState(true);

  const NavActive = window.location.pathname

  const NavItem = [
    { title: t("nav.home"), route: '/' },
    { title: t("nav.product"), route: '/product' },
    { title: t("nav.news"), route: '/news' },
    { title: t("nav.aboutUs"), route: '/about' },
    { title: t("nav.contactUs"), route: '/contactus' }
  ]

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      translateContent();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, []); 


  return (
    <>
      <header id="header">
        <div className="container justify-content-between">
          <div className="d-flex">
            <i className="ri-menu-line d-lg-none" onClick={() => setMenuOpen(true)} />
            <a className="site-logo" href="/">
              <img src={'http://wp.hktifood.com/wp-content/uploads/2023/12/WhatsApp-Image-2023-10-14-at-17.10-2.png'} alt="logo-Nextup" className="logo-header" />
              <div className="text-header">
                KOPERASI HKTI TAMARA BUMI INDONESIA KEPRI
              </div>
            </a>
          </div>
          <nav
            role="navigation"
            id="nav-main"
            className="navigation-main "
          >
            {NavItem.map((data, idx) => {
              return (
                <div key={data.title + idx} className={`item-nav d-none d-lg-block ${NavActive === data.route ? "active" : ""}`} onClick={() => {
                  navigate(data.route);
                  if (data.title === 'Product') dispatch(setProduct(null));
                }}>{data.title}</div>
              )
            })}
            <div className="translate-btn" onClick={() => {
              if (isTranslated) resetTranslation()
              else translateContent()
              if (translate) handleChangeLanguage("en")
              else handleChangeLanguage("id")
              setTranslate(!translate)
            }}>
              {translate ? 'ID' : 'EN'}
            </div>
          </nav>
        </div>
      </header>
      <div className="floating-button-whatsapp">
        <a href="https://wa.me/628117771338" target="_blank" rel="noreferrer" >
          <i className="ri-whatsapp-line" />
        </a>
      </div>
      <div className="d-lg-none">
        <Dialog
          fullScreen
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
        >
          <div className="modal-nav">
            <i className="ri-close-line" onClick={() => setMenuOpen(false)} />
            <nav
              role="navigation"
              id="nav-main"
              className="navigation-main-modal"
            >
              {NavItem.map((data, idx) => {
                return (
                  <div
                    key={data.title + idx}
                    className={`item-nav ${NavActive === data.route ? "active" : ""}`}
                    onClick={() => {
                      navigate(data.route);
                      if (data.title === 'Product') dispatch(setProduct(null));
                      setMenuOpen(false);
                    }}>{data.title}</div>
                )
              })}
            </nav>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default Header;
